import {
  DATE_FORMAT,
  formatUtc,
} from '@emobg/web-utils';

export default [
  {
    property: 'message',
    title: 'Action',
    width: 180,
  },
  {
    property: 'createdAtTs',
    title: 'Date and Time (UTC)',
    transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtendedSeconds),
    width: 180,
  },
  {
    property: 'userName',
    title: 'User',
    width: 200,
  },
];
